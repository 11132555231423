import { CButton, CCol, CFormSwitch, CRow } from "@coreui/react";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { cityStatus_api, exceldownload_api, getcitylist_api, ImageUrl, order_list_api } from "../../API/baseUrl";
import ClearIcon from '@mui/icons-material/Clear';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';


import {
  ExcleDownloadOrder,
  OrderListRequest,
  cityList,
  cityStatusRequest,
  removeDataFromReducer,
} from "../../redux/actions";
import ToastMessage from "../../utils/ToastMessage";
import { TableSk } from "../skelaton/tableSk";
import "./index.css";
import ExcelDownloader from "../../components/excelDownloader";
import { OrderTitle } from "../Title";
import LoaderAnimation from "../../logo-2/loader/loaderanimation";




const Orderlist = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [pending, setPending] = useState(true);
  const [excelLoading, setExcelLoading] = useState(false);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [sortBy, setSortBy] = useState("DESC");
  const [sortByColumnName, setSortByColumnName] = useState("");
  const dispatch = useDispatch();
  const { orderlistdata } = useSelector((state) => state?.orderListReduser || {});
  const { citystatusdata } = useSelector((state) => state?.citystatus) || {};
 

  const { ExcleDownloadOrderInfo } = useSelector(
    (state) => state?.ExcleDownloadOrder || {}
  );


  const [startvalue, setStartvalue] = useState('');
  const [endvalue, setEndvalue] = useState('');


  dayjs.extend(utc);
  dayjs.extend(timezone);

  useEffect(() => {
    if (orderlistdata?.status) {
      
      setData(orderlistdata?.data);
      setTotalRows(orderlistdata?.count);
      setPending(false);
    } else {
      ToastMessage("error", orderlistdata?.message);
    }
  }, [orderlistdata]);
  useEffect(() => {

    document.title = OrderTitle;
  }, []);
  useEffect(() => {
    setPending(true);
    
    const parameter = {
      offset: offset,
      limit: limit,
      search: searchText,
      columnName: sortByColumnName,
      sortBy: sortBy.toUpperCase(),
      // startDate: startvalue ? startvalue?.format('DD-MM-YYYY') : "",
      // endDate: endvalue ? endvalue?.format('DD-MM-YYYY') : "",
      // startDate: startvalue ? startvalue : "",
      // endDate: endvalue ? endvalue : "",
      startDate: startvalue && startvalue !== "Invalid Date" ? startvalue : "",
      endDate: endvalue && endvalue !== "Invalid Date" ? endvalue : "",
      url: order_list_api,
    };
    dispatch(OrderListRequest(parameter));
  }, [offset, limit, searchText, sortByColumnName, sortBy, startvalue, endvalue]);



  useEffect(() => {
    const parameter = {
      offset: offset,
      limit: limit,
      search: searchText,
      columnName: sortByColumnName,
      // startDate: startvalue ? startvalue?.format('DD-MM-YYYY') : "",
      // endDate: endvalue ? endvalue?.format('DD-MM-YYYY') : "",
      // startDate: startvalue  ? startvalue : "",
      // endDate: endvalue ? endvalue : "",
      startDate: startvalue && startvalue !== "Invalid Date" ? startvalue : "",
      endDate: endvalue && endvalue !== "Invalid Date" ? endvalue : "",
      sortBy: sortBy.toUpperCase(),

      url: order_list_api,
    };
    if (citystatusdata?.status) {
      ToastMessage("success", citystatusdata?.message);
      dispatch(OrderListRequest(parameter));
      dispatch(removeDataFromReducer("CITY_STATUS_RESET"));
    } else {
      ToastMessage("error", citystatusdata?.message);
    }
  }, [citystatusdata]);


  
  const handlenavigate = (orderIdFK) => {
    navigate(`/dashboard/createorderlist/${orderIdFK}`)
  }

  
  const datapermission = localStorage.getItem("permissiondata")


  const parsedDatapermission = JSON.parse(datapermission);


  const columns = [
    {
      name: "Id",
      // selector: (row) => row?.orderId ? row?.orderId : "---",
      selector: (row) => (
        <div
          className="column_id"
          onClick={() => handlenavigate(row?.orderId)}
          style={{ cursor: "pointer", color: "blue" }}
        >
          {row?.orderId || "-"}
        </div>
      ),
      sortable: true,
      column_name: "orderId",
      reorder: true,
      width: "100px",
    },
    // {
    //   name: "MagicBag Name",
    //   selector: (row) => row.surpriseBagName ? row?.surpriseBagName : "---",

    //   sortable: true,
    //   column_name: "surpriseBagName",
    //   reorder: true,
    //   width: "250px",
    // },
    {
      name: "Magic Bag Category",
      selector: (row) => row.surpriseBagCategory ? row?.surpriseBagCategory : "---",
      sortable: true,
      column_name: "surpriseBagCategory",
      reorder: true,
      width: "180px",
    },
    {
      name: "Order Amount",
      // selector: (row) => row.totalOrderAmount ? row?.totalOrderAmount : "---",
      selector: (row) => `₹${row?.totalOrderAmount || "---"}`,
      sortable: true,
      column_name: "totalOrderAmount",
      reorder: true,
      width: "180px",
    },
    {
      name: "Restaurant Name",
      selector: (row) => row.restaurantName ? row?.restaurantName : "---",
      sortable: true,
      column_name: "restaurantName",
      reorder: true,
      width: "180px",
    },
    {
      name: "Customer Name",
      selector: (row) => row.customerName ? row?.customerName : "---",
      sortable: true,
      column_name: "customerName",
      reorder: true,
      width: "180px",
    },
    {
      name: "Customer Mobile Number",
      // selector: (row) => row.costumerCountryCode ? row?.costumerCountryCode : "---",
      selector: (row) => `${row?.costumerCountryCode || "--"}  ${row?.costumerMobileNumber || "--"}`,
      sortable: true,
      column_name: "costumerCountryCode",
      reorder: true,
      width: "280px",
    },
    // {
    //   name: "Customer MobileNumber",
    //   selector: (row) => row.costumerMobileNumber ? row?.costumerMobileNumber : "---",
    //   sortable: true,
    //   column_name: "costumerMobileNumber",
    //   reorder: true,
    //   width:"300px",
    // },
    {
      name: "Customer Email",
      selector: (row) => row.costumerEmail ? row?.costumerEmail : "---",
      sortable: true,
      column_name: "costumerEmail",
      reorder: true,
      width: "180px",
    },
    {
      name: "Payment Id",
      selector: (row) => row.paymentId ? row?.paymentId : "---",
      sortable: true,
      column_name: "paymentId",
      reorder: true,
      width: "180px",
    },
    {
      name: "Payment Status",
      // selector: (row) =>
      //  row.paymentStatus ? row?.paymentStatus : "---",
      // {return row?.paymentStatus === "pending" ? "Pending" : row?.paymentStatus === "captured" ? "Successful"  : row?.paymentStatus === "authorized" ? "Pending" : row?.paymentStatus === "failed" ? "Failed" : row?.paymentStatus || "---";},
      selector: (row) => {
        const status = row?.paymentStatus;
        // if (!status) {
        //   return row?.paymentStatus || "---";
        // }
        switch (status) {
          case "pending":
            return "Pending";
          case "captured":
            return "Successful";
          case "authorized":
            return "Pending";
          case "failed":
            return "Failed";
          default:
            return status;
        }
      },
      sortable: true,
      column_name: "paymentStatus",
      reorder: true,
      width: "180px",
    },
    {
      name: "Order Rating",
      selector: (row) => row.orderRating ? row?.orderRating : "---",
      sortable: true,
      column_name: "orderRating",
      reorder: true,
      width: "180px",
    },
    {
      name: "Private Code",
      selector: (row) => row.orderPrivateCode ? row?.orderPrivateCode : "---",
      sortable: true,
      column_name: "orderPrivateCode",
      reorder: true,
      width: "180px",
    },
    {
      name: "Order Status",
      selector: (row) => row.orderStatus ? row?.orderStatus : "---",
      sortable: true,
      column_name: "orderPrivateCode",
      reorder: true,
      width: "180px",
    },
    {
      name: "Order Date & Time",
      selector: (row) => row.orderCreatedAt ? row?.orderCreatedAt : "---",
      sortable: true,
      width: "250px",
    }

    ,

    ...(parsedDatapermission?.orderEdit == 1
      ? [
      
    {
      name: "Invoice",
      selector: (row) => <div className="status_div_change"><button
      style={{
        cursor: row.orderStatus === "Pending" ? "not-allowed" : "pointer",
        backgroundColor: row.orderStatus === "Pending" || row.orderStatus === "Canceled" ? "gray" : "var(--colorused",
      }}
       disabled={row.orderStatus === "Pending" || row.orderStatus === "Canceled"} onClick={() => handleInvoice(row?.invoicePath)}> Download </button></div>,
      sortable: true,
      column_name: "orderRating",
      reorder: true,
    },
        ]
      : []),








  ];



  const handleInvoice = (invoice) => {
    // window.open(`${ImageUrl}${invoice}`)
    window.location.href = `${ImageUrl}${invoice}`;
  }

  const customStyles = {
    headCells: {
        style: {
            fontSize: "16px",
            fontWeight: 500,
        },
    },
};
  const handlePerRowsChange = async (newLimit, offset) => {
    setOffset(offset);
    setLimit(newLimit);
  };

  const handlePageChange = (offset) => {
    setOffset(offset);
  };

  const handleSort = async (column, sortDirection) => {
    setSortBy(sortDirection);
    setSortByColumnName(column.column_name || "");
  };


  const handleclearSearch = () => {
    setSearchText("");
  };


  const callBack = (data) => {
    setSearchText(data);
  };


  const handleExcel = () => {
    setExcelLoading(true)
    const params = {
      // startDate: startvalue ? startvalue : "",
      // endDate: endvalue ? endvalue : "",
      startDate: startvalue && startvalue !== "Invalid Date" ? startvalue : "",
      endDate: endvalue && endvalue !== "Invalid Date" ? endvalue : "",
      url: exceldownload_api,
    };
    dispatch(ExcleDownloadOrder(params));
  };

  // useEffect(() => {
  //   if (ExcleDownloadOrderInfo) {
  //     setExcelLoading(false)
  //     const blob = new Blob([ExcleDownloadOrderInfo], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  //     const link = document.createElement('a');
  //     link.href = window.URL.createObjectURL(blob);
  //     link.download = 'orderlist.xlsx';
  //     link.click();
  //     dispatch(removeDataFromReducer("Excle_Download_RESET"));
  //   } else if (ExcleDownloadOrderInfo?.status === false) {
  //     setExcelLoading(false)
  //     ToastMessage("error", ExcleDownloadOrderInfo.message || "Failed to download Excel file. Please try again.");
  //   }
  //   setPending(false);
  //   setExcelLoading(false)
  // }, [ExcleDownloadOrderInfo]);



  useEffect(() => {
    if (ExcleDownloadOrderInfo) {
      setExcelLoading(false);

      console.log("asdesade", ExcleDownloadOrderInfo)
  
      if (ExcleDownloadOrderInfo?.status !== false) {
        const blob = new Blob([ExcleDownloadOrderInfo], {
          type: 'application/octet-stream', // Force download by using a generic binary type
        });
        const url = URL.createObjectURL(blob); // Create a temporary URL for the file
        const link = document.createElement('a');
        link.href = url;
        link.download = 'orderlist.xlsx'; // Set the file name
        document.body.appendChild(link);
        link.click(); // Simulate the click to trigger download
        document.body.removeChild(link); // Remove the link from the DOM
        URL.revokeObjectURL(url); // Clean up the URL
        dispatch(removeDataFromReducer("Excle_Download_RESET"));
      } else {
        ToastMessage("error", ExcleDownloadOrderInfo.message || "Failed to download Excel file. Please try again.");
      }
    }
    setPending(false);
    setExcelLoading(false);
  }, [ExcleDownloadOrderInfo]);



  return (
    <div>

      <div>

        <CRow>
          <CCol
            className="d-grid gap-2 d-md-flex justify-content-md-start pb-5"
            lg={9}
            md={9}
            sm={8}
            xs={12}
          >
            <h3>Order List</h3>
          </CCol>
          <CCol className="d-grid gap-2 d-md-flex justify-content-md-center pb-5"
            lg={3}
            md={3}
            sm={3}
            xs={7}>
            <div className="search-feild">
              <input
                type="text"
                placeholder="Search"

                style={{ outline: 'none' }}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
              {searchText ? <ClearIcon onClick={handleclearSearch} sx={{ color: `var(--colorused)`, cursor: "pointer", fontSize: "20px" }} /> : ""}

            </div>
          </CCol>

        </CRow>
      </div>


      <ExcelDownloader
      showdoenload= {parsedDatapermission?.orderEdit == 1 ? true : false}
        showdatefilter="true"
        callBack={callBack}
        value={searchText}
        margin="0px"
        handleExcel={handleExcel}
        setSearchtext={setSearchText}
        {...{ startvalue, endvalue, setStartvalue, setEndvalue, excelLoading }}
      />

      {/* <div className="d-grid gap-2 d-md-flex justify-content-md-end pb-5">
        <CButton color="primary" className="me-md-2" onClick={handleAdd}>Add City</CButton>
      </div> */}
      <DataTable
        customStyles={customStyles}
        columns={columns}
        data={data}
        dataAlign="center"
        fixedHeader
        progressPending={pending}
        progressComponent={<LoaderAnimation />}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        fixedHeaderScrollHeight="58vh"
        sortServer
        responsive
        onSort={handleSort}
      />
    </div>
  );
};

export default Orderlist;