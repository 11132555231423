
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "./index.css"
import DataTable from "react-data-table-component";
import { TableSk } from "../skelaton/tableSk";
import { FormControlLabel } from "@mui/material";
import LoaderAnimation from "../../logo-2/loader/loaderanimation";

const DashboardTable = ({ userdata, vendordata }) => {
    const navigate = useNavigate();
    const [typeforlist, setTypeforlist] = useState("user");
    const [pending, setPending] = useState("");


    const handleType = (type) => {
        setTypeforlist(type)
    }

    
  const handleView = (id) => {
    navigate(`/dashboard/vendorView/${id}`);
  }


  const handleUserView = (id) => {
    navigate(`/dashboard/userView/${id}`);
  }


    const vendorcolumns = [
       
        {
            name: "Id",
            width: "130px",
            selector: (row) => <p className="id_navigate" onClick={() => handleView(row?.restaurantIdFk)}>{row?.restaurantIdFk ? `REST00${row.restaurantIdFk}` : "---"}</p>,
            sortable: false,
            column_name: "restaurantIdFk",
            reorder: true,
      
          },
        {
            name: "Total Order Amount",
            selector: (row) => (row.totalOrderAmount ? `₹ ${row?.totalOrderAmount}` : "---"),
            column_name: "type",
            width: "180px",
            reorder: true,
            cell: (row) => (
                <div className="">
                    {row.totalOrderAmount ? `₹ ${row?.totalOrderAmount}` : "---"}
                </div>
            ),
        },
        {
            name: "Total Quantity",
            selector: (row) => (row.totalQuantity ? row.totalQuantity : "---"),
            column_name: "type",
            width: "180px",
            reorder: true,
            cell: (row) => (
                <div className="">
                    {row.totalQuantity ? row.totalQuantity : "---"}
                </div>
            ),
        },
        {
            name: "Transaction Count",
            selector: (row) => (row.transactionCount ? row.transactionCount : "---"),
            column_name: "type",
            width: "180px",
            reorder: true,
            cell: (row) => (
                <div className="">
                    {row.transactionCount ? row.transactionCount : "---"}
                </div>
            ),
        },

    ];


    const usercolumns = [
        {
            name: "Id",
            width: "130px",
            selector: (row) => <p className="id_navigate" onClick={() => handleUserView(row?.userIdFk)}>
              {row?.userIdFk ? `UID00${row.userIdFk}` : "---"}
      
            </p>,
            sortable: false,
            column_name: "userIdFk",
            reorder: true,
      
          },
        {
            name: "Total Order Amount",
            selector: (row) => (row.totalOrderAmount ? `₹ ${row?.totalOrderAmount}` : "---"),
            column_name: "type",
            width: "180px",
            reorder: true,
            cell: (row) => (
                <div className="">
                    {row.totalOrderAmount ? `₹ ${row?.totalOrderAmount}` : "---"}
                </div>
            ),
        },
        {
            name: "Total Quantity",
            selector: (row) => (row.totalQuantity ? row.totalQuantity : "---"),
            column_name: "type",
            width: "180px",
            reorder: true,
            cell: (row) => (
                <div className="">
                    {row.totalQuantity ? row.totalQuantity : "---"}
                </div>
            ),
        },
        {
            name: "Transaction Count",
            selector: (row) => (row.transactionCount ? row.transactionCount : "---"),
            column_name: "type",
            width: "180px",
            reorder: true,
            cell: (row) => (
                <div className="">
                    {row.transactionCount ? row.transactionCount : "---"}
                </div>
            ),
        },

    ];

    const customStyles = {
        headCells: {
            style: {
                fontSize: "16px",
                fontWeight: 500,
            },
        },
    };


    return (
        <div>

            <Tabs style={{ }} >
                <TabList className="TabList">
                    <Tab className="Tab" onClick={() => handleType("user")}>
                        Top Users
                    </Tab>
                    <Tab className="Tab" onClick={() => handleType("vendor")}>
                        Top Vendors
                    </Tab>
                </TabList>
                <TabPanel>
                    <div className="m-t-10">
                        <DataTable
                            customStyles={customStyles}
                            columns={usercolumns}
                            data={userdata}
                            dataAlign="center"
                            fixedHeader
                            progressPending={pending}
                            progressComponent={<LoaderAnimation />}
                            fixedHeaderScrollHeight="58vh"
                            sortServer
                            responsive
                        />
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className="m-t-10">
                        <DataTable
                            customStyles={customStyles}
                            columns={vendorcolumns}
                            data={vendordata}
                            dataAlign="center"
                            fixedHeader
                            progressPending={pending}
                            progressComponent={<LoaderAnimation />}
                            fixedHeaderScrollHeight="58vh"
                            sortServer
                            responsive
                        />
                    </div>
                </TabPanel>
            </Tabs>



        </div>
    );
};

export default DashboardTable;
