import * as Yup from "yup";
const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const loginschema = Yup.object().shape({
  email: Yup.string()
    .matches(emailRegex, "Please enter valid email")
    .required("This is required"),
  password: Yup.string()
    .required("This is required")
    .min(6, "Password must be atleast 6 digits"),
});

export const sendemailshema = Yup.object().shape({
  email: Yup.string()
    .matches(emailRegex, "Please enter valid email")
    .required("This is required"),
});

export const adminuserscehema = Yup.object().shape({
  email: Yup.string()
    .matches(emailRegex, "Please enter valid email")
    .required("This is required"),
    
  password: Yup.string()
  .required("This is required")
  .min(6, "Password must be atleast 6 digits"),
  roleid: Yup.mixed()
  .required("This is required"),
});

export const countryaddshema = Yup.object().shape({
  countryName: Yup.string()
    .matches(
      /^(?!.*\d)(?!\s*$).+$/,
      "Must not contain numbers and must not be only spaces"
    )
    .required("This field is Required"),
  countryShortCode: Yup.string()
    .matches(/\S/, "Must not be only spaces")
    .required("This field is Required"),
  countryISD: Yup.string()
    .matches(/\S/, "Must not be only spaces")
    .required("This field is Required"),
  countryCurrency: Yup.string()
    .matches(/\S/, "Must not be only spaces")
    .required("This field is Required"),
  file: Yup.mixed().required("This field is Required"),
});

export const stateaddshema = Yup.object().shape({
  countryname: Yup.string()
    .matches(/\S/, "Must not be only spaces")
    .required("This field is Required"),
  stateName: Yup.string()
    .matches(
      /^(?!.*\d)(?!\s*$).+$/,
      "Must not contain numbers and must not be only spaces"
    )
    .required("This field is Required"),
});

export const adminroleValidation = Yup.object().shape({
  user_role: Yup.string()
    .matches(/\S/, "Must not be only spaces")
    .required("This field is Required"),
});

export const Settingaddshema = Yup.object().shape({
  type: Yup.string()
    .matches(/\S/, "Must not be only spaces")
    .required("This field is Required"),
  data: Yup.string()
    .matches(/^(?!-).*$/, "Negative values are not allowed") // Disallow negative values
    .matches(/\S/, "Must not be only spaces") // Disallow strings composed only of spaces
    .required("This field is Required"),
  description: Yup.string()
    .matches(/\S/, "Must not be only spaces")
    .required("This field is Required"),
});

export const Settingeditshema = Yup.object().shape({

// discountType: Yup.string()
// .oneOf(['%', '₹', 'period'], 'Invalid discount type')
// .required('Discount Type is required'),

data: Yup.number()
.typeError('Value must be a number')
.required('This field is Required')
.test('valid-value', 'Value must be between 0 and 100', function(value) {
  const { discountType } = this.parent;

  if (discountType === '%' && (value < 0 || value > 100)) {
    return false;
  }

  return value >= 0; 
}),

  description: Yup.string()
    .matches(/\S/, "Must not be only spaces")
    .required("This field is Required"),
});

export const cityaddshema = Yup.object().shape({
  countryIdFk: Yup.string()
    .matches(/\S/, "Must not be only spaces")
    .required("This field is Required"),
  stateIdFk: Yup.string()
    .matches(/\S/, "Must not be only spaces")
    .required("This field is Required"),
  cityName: Yup.string()
    .matches(
      /^(?!.*\d)(?!\s*$).+$/,
      "Must not contain numbers and must not be only spaces"
    )
    .required("This field is Required"),
});

export const specialityshema = Yup.object().shape({
  specialty: Yup.string()
    .matches(/\S/, "Must not be only spaces")
    .required("This field is Required"),
});

export const bagsizeaddshema = Yup.object().shape({
  surpriseBagSize: Yup.string()
    .matches(/\S/, "Must not be only spaces")
    .required("Bag Size is required"),

  // restaurantTypeIdFk: Yup.string()
  //   .matches(/\S/, "Must not be only spaces")
  //   .required("Restaurant Type is required"),

  // commission: Yup.string()
  //   .matches(/\S/, "Must not be only spaces")
  //   .required("commission is required"),
  commission: Yup.number()
    .required("Commission is required")
    .min(0, "Commission cannot be negative")
    .max(100, "Commission cannot be more than 100%")
    .typeError("Commission must be a number")
    .transform((value) => (isNaN(value) ? undefined : value)), // to handle non-numeric inputs
    
    discount: Yup.number()
    .required("Discount is required")
    .min(0, "Discount cannot be negative")
    .max(100, "Discount cannot be more than 100%")
    .typeError("Discount must be a number")
    .transform((value) => (isNaN(value) ? undefined : value)),

  surpriseBagRetailPrice: Yup.number()
    .typeError("Retail Price must be a number")
    .required("Retail Price is required")
    .min(0, "Retail Price must be greater than or equal to 0"),

  surpriseBagSellingPrice: Yup.number()
    .typeError("Selling Price must be a number")
    .required("Selling Price is required")
    .min(0, "Selling Price must be greater than or equal to 0"),
});

export const addtypeshema = Yup.object().shape({
  type: Yup.string()
    .matches(/\S/, "Must not be only spaces")
    .required("This field is Required"),
});
export const addBankshema = Yup.object().shape({
  bank: Yup.string()
    .matches(/\S/, "Must not be only spaces")
    .required("This field is Required"),
});
export const rejectStatusShema = Yup.object().shape({
  reasonOfRejection: Yup.string()
    .matches(/\S/, "Must not be only spaces")
    .required("This field is Required"),
});

export const addFaqshema = Yup.object().shape({
  question: Yup.string()
    .matches(/\S/, "Must not be only spaces")
    .required("This field is Required"),
  answer: Yup.string()
    .matches(/\S/, "Must not be only spaces")
    .required("This field is Required"),
});

export const AccountStatusShema = Yup.object().shape({
  restaurantTypeIdFk: Yup.string().required("This field is Required"),
  // commission: Yup.number().required('This field is required').typeError("Must be a number"),
  commission: Yup.number()
    .required('This field is required')
    .typeError('Must be a number')
    .min(0, 'Must be greater than or equal to 0')
    .max(100, 'Must be less than or equal to 100')
    .test(
      'is-decimal',
      'Must be a valid number (including decimals)',
      (value) => value === undefined || /^(\d+(\.\d+)?)?$/.test(String(value))
    )
});

export const acountReasonShema = Yup.object().shape({
  // restaurantTypeIdFk: Yup.string().required('This field is Required'),
  reasonOfRejection: Yup.string()
    .matches(/\S/, "Must not be only spaces")
    .required("This field is Required"),
});
export const documentUploadSchema = Yup.object().shape({
  bankIdFk: Yup.string().required('This field is required'),
  accountHolderName: Yup.string() .matches(/^[A-Za-z\s]+$/, "Name must only contain alphabets")
  .test("not-only-spaces", "Name must not be only spaces", (value) => value && value.trim() !== "")
  .required('This field is required'),
  accountNumber: Yup.number().required('This field is required').typeError("Must be a number"),
  ifscCode: Yup.string().matches(/\S/, ("Must not be only spaces")).required('This field is required'),
  // fssaiLicence: Yup.mixed(),
  fssaiLicence: Yup.mixed()
  .typeError("Must be a number") .test('fssaiLicence', 'This field is required', function(value) {
    const { fssaiLicenceExpiryDate } = this.parent; 
    if (fssaiLicenceExpiryDate && !value) {
      return false; 
    }
    return true; 
  }),
  // fssaiLicenceExpiryDate: Yup.string().required('This field is required'),
  fssaiLicenceExpiryDate: Yup.string()
  .test('expiry-date-required', 'This field is required', function(value) {
    const { fssaiLicence } = this.parent; 
    if (fssaiLicence && !value) {
      return false; 
    }
    return true; 
  }),
  panCard: Yup.mixed().required('This field is required'),
  regularGstIn: Yup.mixed().required('This field is required'),
  branchName: Yup.string().matches(/^(?!.*\d)(?!\s*$).+$/, "Must not contain numbers and must not be only spaces").required('This field is required'),
  foodImage: Yup.mixed().required('This field is required'),
  restaurantLogo: Yup.mixed().required('This field is required'),
});