import { CButton, CCol, CFormSwitch, CRow } from "@coreui/react";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { billing_list_api, cityStatus_api, billing_list_export_download_api, getcitylist_api, ImageUrl } from "../../API/baseUrl";
import ClearIcon from '@mui/icons-material/Clear';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';


import {
    ExcleDownloadOrder,
    OrderListRequest,
    cityList,
    cityStatusRequest,
    removeDataFromReducer,
} from "../../redux/actions";
import ToastMessage from "../../utils/ToastMessage";
import { TableSk } from "../skelaton/tableSk";
import "./index.css";
import ExcelDownloader from "../../components/excelDownloader";
import { BillingTitle } from "../Title";
import LoaderAnimation from "../../logo-2/loader/loaderanimation";



const Billinglist = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [pending, setPending] = useState(true);
    const [excelLoading, setExcelLoading] = useState(false);
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(1);
    const [searchText, setSearchText] = useState("");
    const [sortBy, setSortBy] = useState("DESC");
    const [sortByColumnName, setSortByColumnName] = useState("");
    const dispatch = useDispatch();
    const { orderlistdata } = useSelector((state) => state?.orderListReduser || {});
    const { citystatusdata } = useSelector((state) => state?.citystatus) || {};


    const { ExcleDownloadOrderInfo } = useSelector(
        (state) => state?.ExcleDownloadOrder || {}
    );


    const [startvalue, setStartvalue] = useState('');
    const [endvalue, setEndvalue] = useState('');


    dayjs.extend(utc);
    dayjs.extend(timezone);
    useEffect(() => {

        document.title = BillingTitle;
      }, []);
    useEffect(() => {
        if (orderlistdata?.status) {
            const modifiedData = orderlistdata?.data.map((item, index) => ({
                ...item, // Spread the original item data
                index: index + 1, // Add an index key (starting from 1)
            }));
            setData(modifiedData);
            setTotalRows(orderlistdata?.count);
            setPending(false);
        } else {
            ToastMessage("error", orderlistdata?.message);
        }
    }, [orderlistdata]);

    useEffect(() => {
        console.log(startvalue,endvalue,"eerree")
        const parameter = {
            offset: offset,
            limit: limit,
            search: searchText,
            columnName: sortByColumnName,
            sortBy: sortBy.toUpperCase(),   
            startDate: startvalue && startvalue !== "Invalid Date" ? startvalue : "",
            endDate: endvalue && endvalue !== "Invalid Date" ? endvalue : "",         
            url: billing_list_api,
        };
        dispatch(OrderListRequest(parameter));
    }, [offset, limit, searchText, sortByColumnName, sortBy, startvalue, endvalue]);

    //   const handleStatus = (id) => {
    //     const parameter = {
    //       url: `${cityStatus_api}/${id} `,
    //     };
    //     dispatch(cityStatusRequest(parameter));
    //   };

    console.log(startvalue ,endvalue,"kkkk123" )

    useEffect(() => {
        const parameter = {
            offset: offset,
            limit: limit,
            search: searchText,
            columnName: sortByColumnName,
            startDate: startvalue && startvalue !== "Invalid Date" ? startvalue : "",
            endDate: endvalue && endvalue !== "Invalid Date" ? endvalue : "",
            sortBy: sortBy.toUpperCase(),
            url: billing_list_api,
        };
        if (citystatusdata?.status) {
            ToastMessage("success", citystatusdata?.message);
            dispatch(OrderListRequest(parameter));
            dispatch(removeDataFromReducer("CITY_STATUS_RESET"));
        } else {
            ToastMessage("error", citystatusdata?.message);
        }
    }, [citystatusdata]);



    const handlenavigate = (orderIdFK) => {
        navigate(`/dashboard/createorderlist/${orderIdFK}`)
    }

    const columns = [
        {
            name: "S.no.",
            selector: (row) => row.orderIdFK ? row.orderIdFK : "---",
            sortable: true,
            column_name: "orderIdFK",
            reorder: true,
            width: "100px",
        },
        {
            name: "Transaction ID",
            selector: (row) => row.paymentId ? row.paymentId : "---",
            sortable: true,
            column_name: "paymentId",
            reorder: true,
            width: "180px",
        },
        {
            name: "Date & Time",
            selector: (row) => row.orderCreatedAt ? row?.orderCreatedAt : "---",
            sortable: true,
            reorder: true,
            width: "180px",
            column_name: "orderCreatedAt",
        },
        {
            name: "Transaction Type",
            selector: (row) => row.transactionType ? row?.transactionType : "Online",
            sortable: true,
            column_name: "transactionType",
            reorder: true,
            width: "180px",
        },
        {
            name: "Type",
            selector: (row) => row.paymentMode ? row?.paymentMode : "---",
            sortable: true,
            column_name: "paymentMode",
            reorder: true,
            width: "150px",
        },

        {
            name: "Restaurant Name",
            selector: (row) => row.restaurantName ? row?.restaurantName : "---",
            sortable: true,
            column_name: "restaurantName",
            reorder: true,
            width: "180px",
        },

        {
            name: "Restaurant ID",
            selector: (row) => row.restaurantID ? row?.restaurantID : "---",
            sortable: true,
            column_name: "restaurantID",
            reorder: true,
            width: "150px",
        },
        {
            name: "Currency",
            selector: (row) => row.Currency ? row?.Currency : "INR",
            sortable: true,
            column_name: "restaurantName",
            reorder: true,
            width: "140px",
        },
        {
            name: "Bill Amount",
            selector: (row) => `₹${row?.totalOrderAmount || "---"}`,
            sortable: true,
            column_name: "totalOrderAmount",
            reorder: true,
            width: "140px",
        },
        {
            name: "Commission %",
            selector: (row) => `${row?.vendorCommissionInPercent || "---"}`,
            sortable: true,
            column_name: "vendorCommissionInPercent",
            reorder: true,
            width: "160px",
        },
        {
            name: "Commission Amount",
            selector: (row) => `${row?.vendorCommissionCharged || "---"}`,
            sortable: true,
            column_name: "vendorCommissionCharged",
            reorder: true,
            width: "210px",
        },
        {
            name: "Tax on Commission",
            selector: (row) => `${(row?.vendorGstOnCommissionCharged) || "---"}`,
            // selector: (row) => `${(row?.vendorGstOnCommissionCharged)?.toFixed(2) || "---"}`,
            sortable: true,
            column_name: "vendorGstOnCommissionCharged",
            reorder: true,
            width: "200px",
        },
        {
            name: "Charitable Amount",
            selector: (row) => `${row?.charitableAmount || "---"}`,
            sortable: true,
            column_name: "charitableAmount",
            reorder: true,
            width: "190px",
        },
        {
            name: "Settlement Status",
            selector: (row) => `${row?.settlementStatus || "---"}`,
            sortable: true,
            column_name: "settlementStatus",
            reorder: true,
            width: "180px",
        },
        {
            name: "Settlement Date",
            selector: (row) => `${row?.settlementDate || "---"}`,
            sortable: true,
            column_name: "settlementDate",
            reorder: true,
            width: "180px",
        },
        {
            name: "UTR Number",
            selector: (row) => `${row?.utrno || "---"}`,
            sortable: true,
            column_name: "utrno",
            reorder: true,
            width: "150px",
        },
        {
            name: "Platform Fee Amount",
            selector: (row) => `${row?.platformFeeInRupees || "---"}`,
            sortable: true,
            column_name: "platformFeeInRupees",
            reorder: true,
            width: "220px",
        },
        {
            name: "Platform Fee Gst %",
            selector: (row) => `${row?.platformFeeGstInPercent || "---"}`,
            sortable: true,
            column_name: "platformFeeGstInPercent",
            reorder: true,
            width: "200px",
        },
        {
            name: "Total Tax",
            selector: (row) => `${(row?.totalTaxChargedToVendor) || "---"}`,
            // selector: (row) => `${(row?.totalTaxChargedToVendor)?.toFixed(2) || "---"}`,
            sortable: true,
            column_name: "totalTaxChargedToVendor",
            reorder: true,
            width: "120px",
        },
        {
            name: "TotalAmount Paid To Vendor",
            // selector: (row) => `${(row?.totalAmountPaidToVendor)?.toFixed(2) || "---"}`,
            selector: (row) => `${(row?.totalAmountPaidToVendor)|| "---"}`,
            sortable: true,
            column_name: "totalAmountPaidToVendor",
            reorder: true,
            width: "260px",
        },
        
        {
            name: "Cover Charge",
            selector: (row) => `${row?.coverCharge || "---"}`,
            sortable: true,
            column_name: "coverCharge",
            reorder: true,
            width: "150px",
        },
        {
            name: "Discount Type",
            selector: (row) => `${row?.discountType || "---"}`,
            sortable: true,
            column_name: "discountType",
            reorder: true,
            width: "160px",
        },
        {
            name: "Instant Discount",
            selector: (row) => `${row?.instantDiscount || "---"}`,
            sortable: true,
            column_name: "instantDiscount",
            reorder: true,
            width: "180px",
        },
        {
            name: "Promo Share",
            selector: (row) => `${row?.promoShare || "---"}`,
            sortable: true,
            column_name: "promoShare",
            reorder: true,
            width: "150px",
        },
        {
            name: "Commissionable Amount",
            selector: (row) => `${row?.commissionableAmount || "---"}`,
            sortable: true,
            column_name: "commissionableAmount",
            reorder: true,
            width: "235px",
        },
    ];



    const handleInvoice = (invoice) => {
        window.open(`${ImageUrl}/${invoice}`, '_blank')
    }


    const customStyles = {
        headCells: {
            style: {
                fontSize: "16px",
                fontWeight: 500,
            },
        },
    };

    
    const handlePerRowsChange = async (newLimit, offset) => {
        setOffset(offset);
        setLimit(newLimit);
    };

    const handlePageChange = (offset) => {
        setOffset(offset);
    };

    const handleSort = async (column, sortDirection) => {
        setSortBy(sortDirection);
        setSortByColumnName(column.column_name || "");
    };


    const handleclearSearch = () => {
        setSearchText("");
    };


    const callBack = (data) => {
        setSearchText(data);
    };


    const handleExcel = () => {
        setExcelLoading(true)
        const params = {
            startDate: startvalue && startvalue !== "Invalid Date" ? startvalue : "",
            endDate: endvalue && endvalue !== "Invalid Date" ? endvalue : "",
            url: billing_list_export_download_api,
        };
        dispatch(ExcleDownloadOrder(params));
    };
console.log("searchText",data)
    useEffect(() => {
        if (ExcleDownloadOrderInfo) {
            setExcelLoading(false)
            const blob = new Blob([ExcleDownloadOrderInfo], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'billinglist.xlsx';
            link.click();
            dispatch(removeDataFromReducer("Excle_Download_RESET"));
        } else if (ExcleDownloadOrderInfo?.status === false) {
            setExcelLoading(false)
            ToastMessage("error", ExcleDownloadOrderInfo.message || "Failed to download Excel file. Please try again.");
        }
        setPending(false);
        setExcelLoading(false)
    }, [ExcleDownloadOrderInfo]);



  
    const datapermission = localStorage.getItem("permissiondata")


    const parsedDatapermission = JSON.parse(datapermission);




    return (
        <div>

            <div>

                <CRow>
                    <CCol
                        className="d-grid gap-2 d-md-flex justify-content-md-start pb-5"
                        lg={9}
                        md={9}
                        sm={8}
                        xs={12}
                    >
                        <h3>Billing List</h3>
                    </CCol>
                    <CCol className="d-grid gap-2 d-md-flex justify-content-md-center pb-5"
                        lg={3}
                        md={3}
                        sm={3}
                        xs={7}>
                        <div className="search-feild">
                            <input
                                type="text"
                                placeholder="Search"

                                style={{ outline: 'none' }}
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                            />
                            {searchText ? <ClearIcon onClick={handleclearSearch} sx={{ color: `var(--colorused)`, cursor: "pointer", fontSize: "20px" }} /> : ""}

                        </div>
                    </CCol>

                </CRow>
            </div>


            <ExcelDownloader
              showdoenload= {parsedDatapermission?.billingsEdit == 1 ? true : false}
                showdatefilter="true"
                callBack={callBack}
                value={searchText}
                margin="0px"
                handleExcel={handleExcel}
                setSearchtext={setSearchText}
                {...{ startvalue, endvalue, setStartvalue, setEndvalue , excelLoading}}
            />

            <DataTable
                customStyles={customStyles}
                columns={columns}
                data={data}
                dataAlign="center"
                fixedHeader
                progressPending={pending}
                progressComponent={<LoaderAnimation/>}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                fixedHeaderScrollHeight="58vh"
                sortServer
                responsive
                onSort={handleSort}
            />
        </div>
    );
};

export default Billinglist;